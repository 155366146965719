import apis from "../../common/js/api2";
import tools from "../../../extends/tools";

export default {
    onSubmit() {
        this.getList();
    },
    handleSizeChange(val) {
        this.req.limit = val;
        this.getList();
    },
    handleCurrentChange(val) {
        this.req.page = val;
        this.getList();
    },
    setPrice(m) {
        return tools.setPrice(m);
    },
    //新增单据，跳转
    addPurchase(id = 0) {
        this.$router.push({
            path: '/sale_tui/add?id=' + id
        });
    },
    enable(id, item) {
        if (item.enable_status == '开启') {
            item.enable_status = '禁用';
        } else {
            item.enable_status = '开启';
        }
        apis.supplierCateSet({ id: id }).then(res => {
            tools.msg(res, this, 1);
        }).catch(err => {
            tools.err(err, this);
        });
    },
    getList() {
        apis.sale_tuiList(this.req).then((res) => {
            this.tableData = res.data.data;
            this.tableData.forEach((item, key) => {
                for (let i in item) {
                    if (i === 'beginning_balance' || i === 'balance') {
                        item[i] = tools.setPrice(item[i]);
                    }
                }
            });
            this.allSum = res.count;
            this.allSum = tools.setPrice(res.count);
            this.total = res.data.total;
        }).catch((err) => {
            this.$message('网络或程序错误，请稍候重试！', 'error');
        });
    },
    exit() {
        this.$emit('shareExit')
    },
    shareExit() {
        this.createShareFlag = false;
        this.shareListFlag = false;
    },
    remove(id, key) {
        this.$confirm('确定要删除吗?', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            apis.sale_tuiDelete({ id: id }).then((info) => {
                if (info.code == 200) {
                    this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: info.msg
                    });
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },
    //导出Excel
    exportData() {
        this.exportExcelFlag = true;
        apis.sale_tuiExcel(this.req).then(res => {
            // alert(1);
            this.exportStatus = 2;
            let blob = new Blob([res]);
            let url = URL.createObjectURL(blob);
            setTimeout(() => {
                this.exportExcelFlag = false;
                this.exportStatus = 1;
            }, 2500);
            // 下载该blob链接
            tools.downloadFile(url, '销售退货单-' + tools.getDateTime() + '.xls');
        }).catch(err => {
            console.log(err);
            // this.exportStatus = 3;
        });
    },

    edit(id) {
        this.$router.push({
            path: '/sale_tui/add?id=' + id
        });
    },
    detail(id) {
        this.$router.push({
            path: '/sale_tui/detail?id=' + id
        });
    }

}